import type { BlogArticleSummary } from "@/modules/corporate/types/blog"
import type { Page } from "@/modules/corporate/types/pages"
import type { FunctionComponent } from "react"

import cn from "classnames"

import { ElementGrid } from "@jobteaser/ui-kit/components/ElementGrid/ElementGrid"

import { BlogCard } from "./BlogCard"

import styles from "./BlogIndex.module.css"

type BlogIndexResults = {
  articles: BlogArticleSummary[]
  emphasizeTheFirstArticle?: boolean
  page: Page
}

export const BlogIndexResults: FunctionComponent<BlogIndexResults> = ({
  articles,
  emphasizeTheFirstArticle = false,
  page,
}) => {
  return (
    <ElementGrid
      has4Cols={emphasizeTheFirstArticle}
      className={cn(styles.list, { [styles["list--emphasize"]]: emphasizeTheFirstArticle })}
    >
      {articles.map((article, idx) => (
        <BlogCard
          key={article.title}
          article={article}
          page={page}
          with2Columns={emphasizeTheFirstArticle && idx !== 0}
        />
      ))}
    </ElementGrid>
  )
}
