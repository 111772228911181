import type { BlogArticleSummary } from "@/modules/corporate/types/blog"
import type { Page } from "@/modules/corporate/types/pages"
import type { FunctionComponent } from "react"

import cn from "classnames"
import Image from "next/image"

import { Button } from "@jobteaser/ui-kit/components/Button/Button"
import { Text, TEXT_TYPES, TEXT_WEIGHTS } from "@jobteaser/ui-kit/components/Text/Text"

import { InternalLink } from "@/modules/corporate/components/InternalLink"
import { URL_PATHNAME } from "@/modules/corporate/constants"
import { useCorporateTrackingClick } from "@/modules/corporate/tracking/useCorporateTrackingClick"
import { useTranslation } from "@/modules/i18n/components/useTranslation"
import { useLocale } from "@/modules/locales/useLocale"

import { ArticleCategory } from "./ArticleCategory"

import styles from "./BlogCard.module.css"

type BlogCardProps = {
  article: BlogArticleSummary
  className?: string
  page: Page
  with2Columns?: boolean
  withImage?: boolean
}

export const BlogCard: FunctionComponent<BlogCardProps> = ({
  article: { articleType, category, image, slug, summary, title },
  className,
  page,
  with2Columns = false,
  withImage = true,
}) => {
  const locale = useLocale
  const trackClickEvent = useCorporateTrackingClick()
  const { t } = useTranslation(["corporate_blog"])

  return (
    <article
      className={cn(className, styles.card, {
        [styles["card--article"]]: articleType[0] === "article",
        [styles["card--guide"]]: articleType[0] === "guide",
        [styles["card--2cols"]]: with2Columns,
      })}
    >
      {["article", "gated_content"].includes(articleType[0]) && withImage && (
        <picture className={styles.cardImageWrapper}>
          <Image
            src={image[0].url}
            alt={image[0].description || ""}
            width={image[0].width || undefined}
            height={image[0].height || undefined}
            className={styles.cardImage}
            sizes="50vw"
          />
        </picture>
      )}
      {!!category.length && <ArticleCategory category={category[0].title} className={styles.category} />}
      <Text className={styles.title} type={TEXT_TYPES.SUBHEAD} weight={TEXT_WEIGHTS.BLACK} tag="h3" resetSpacing>
        {articleType[0] === "article" ? (
          <InternalLink
            page={{ slug: `${page.slug}/${slug}`, title }}
            className={styles.cardLink}
            onClick={() =>
              trackClickEvent("corporate_link_resources_article", {
                clicked_url: `/${locale}/${URL_PATHNAME}/${page.slug}/${slug}`,
              })
            }
          >
            {title}
          </InternalLink>
        ) : (
          title
        )}
      </Text>
      {articleType[0] === "guide" && <Text className={styles.summary}>{summary}</Text>}
      {articleType[0] === "guide" && (
        <Button
          component={InternalLink}
          page={{ slug: `${page.slug}/${slug}`, title }}
          className={styles.cardLink}
          onClick={() =>
            trackClickEvent("corporate_link_resources_article", {
              clicked_url: `/${locale}/${URL_PATHNAME}/${page.slug}/${slug}`,
            })
          }
          variant="ghost"
        >
          {t("corporate_blog.cards.guide.button")}
        </Button>
      )}
      {articleType[0] === "gated_content" && (
        <Button
          component={InternalLink}
          page={{ slug: `${page.slug}/${slug}`, title }}
          className={styles.cardLink}
          onClick={() =>
            trackClickEvent("corporate_link_resources_article", {
              clicked_url: `/${locale}/${URL_PATHNAME}/${page.slug}/${slug}`,
            })
          }
          target="_blank"
          variant="ghost"
        >
          {t("corporate_blog.cards.gated_content.button")}
        </Button>
      )}
    </article>
  )
}
