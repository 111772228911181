import type { BlogSection } from "@/modules/corporate/types/sections"
import type { FunctionComponent } from "react"

import dynamic from "next/dynamic"

import { URL_PATHNAME } from "@/modules/corporate/constants"
import { useCorporateTrackingClick } from "@/modules/corporate/tracking/useCorporateTrackingClick"
import { useLocale } from "@/modules/locales/useLocale"

import styles from "./BlogIndex.module.css"

type BlogRelatedCardsProps = {
  cards: BlogSection["relatedCards"]
}

const DynamicCard = dynamic(() => import("@/modules/corporate/components/Card/Card").then(mod => mod.Card))
const DynamicDetailedCard = dynamic(() =>
  import("@/modules/corporate/components/DetailedCard/DetailedCard").then(mod => mod.DetailedCard)
)

export const BlogRelatedCards: FunctionComponent<BlogRelatedCardsProps> = ({ cards }) => {
  const locale = useLocale()
  const trackClickEvent = useCorporateTrackingClick()

  return (
    <>
      {cards.map(card => {
        const { type } = card

        if (type === "growth___corporate_page_section___card") {
          return (
            <DynamicCard
              key={`${card.type}_${card.title}`}
              className={styles.relatedCard}
              title={card.title}
              richDescription={card.description}
              ctaLink={!!card.ctaLink.length && card.ctaLink[0]}
              ctaText={card.ctaTitle}
              theme={card.theme[0]}
              onCTAClick={() =>
                trackClickEvent("corporate_cta_card", {
                  clicked_url: `/${locale}/${URL_PATHNAME}/${card.ctaLink[0].slug}`,
                  visual_context: card.ctaTitle,
                })
              }
            />
          )
        }

        if (type === "growth___corporate_page_section___detailed_card") {
          return (
            <DynamicDetailedCard
              key={`${card.type}_${card.title}`}
              bgColor={card.backgroundColor}
              category={card.category}
              className={styles.relatedCard}
              ctaLink={card.ctaLink[0]}
              ctaText={card.ctaTitle}
              ctaUrl={card.ctaUrl}
              description={card.description}
              imageDescription={(!!card.image.length && card.image[0].description) || ""}
              imageHeight={(!!card.image.length && card.image[0].height) || undefined}
              imageURL={card.image[0].url}
              imageWidth={(!!card.image.length && card.image[0].width) || undefined}
              theme={(card.theme[0] || "").toLowerCase()}
              title={card.title}
              underlineColor={card.underlineColor}
            />
          )
        }

        return null
      })}
    </>
  )
}
