import type { FunctionComponent, PropsWithChildren } from "react"

import classNamesMaker from "classnames"

// eslint-disable-next-line no-restricted-imports
import { FilterBar as FilterBarUiKit } from "@jobteaser/ui-kit/components/FilterBar/FilterBar"

import styles from "./FilterBar.module.css"

type FilterBarProps = PropsWithChildren<{
  className?: string
}> &
  Record<string, unknown>

export const FilterBar: FunctionComponent<FilterBarProps> = props => {
  const { children, className, ...restProps } = props

  const classNames = classNamesMaker(styles.filterBar, className)

  return (
    <FilterBarUiKit className={classNames} {...restProps}>
      {children}
    </FilterBarUiKit>
  )
}
