import type { BlogArticleSummary, BlogFilters } from "@/modules/corporate/types/blog"
import type { PagePagination } from "@/modules/corporate/types/pagePagination"
import type { Page } from "@/modules/corporate/types/pages"
import type { BlogSection } from "@/modules/corporate/types/sections"
import type { SimpleSelectOptions } from "@/modules/uiKitOverrides/SelectFilter/Select"
import type { FunctionComponent } from "react"

import { usePathname, useSearchParams } from "next/navigation"

import { Layout } from "@jobteaser/ui-kit/components/Layout/Layout"
import { TEXT_WEIGHTS } from "@jobteaser/ui-kit/components/Text/Text"

import { Title } from "@/modules/corporate/components/Sections/Title/Title"
import teasyTriangle from "@/modules/corporate/images/teasy-outline-triangle.svg"
import { useTranslation } from "@/modules/i18n/components/useTranslation"
import { RichText } from "@/modules/kontent/components/RichText"
import { Link } from "@/modules/routing/components/Link"
import { useHref } from "@/modules/routing/hooks/useHref"
import { useRouter } from "@/modules/routing/hooks/useRouter"
import { getAssetImportUrl } from "@/modules/staticAssets/getAssetImportUrl"
import { FilterBar } from "@/modules/uiKitOverrides/FilterBar/FilterBar"
import { Pagination } from "@/modules/uiKitOverrides/Pagination/Pagination"
import { Filter } from "@/modules/uiKitOverrides/SelectFilter/Filter"

import { BlogIndexResults } from "./BlogIndexResults"
import { BlogRelatedCards } from "./BlogRelatedCards"

import styles from "./BlogIndex.module.css"

const DEFAULT_RELATED_CARDS_POSITION = 3 // 0 based index

type BlogIndexPropsType = {
  page: Page
  pagePagination: PagePagination<BlogArticleSummary, BlogFilters> | null
  section: BlogSection
}

type FilterValue = SimpleSelectOptions[] | SimpleSelectOptions

function updateUrlWithQuery(url: string, query: Record<string, string[]>): string {
  const [path, search] = url.split("?")
  const searchParams = new URLSearchParams(search || "")

  searchParams.delete("page")

  Object.entries(query).forEach(([key, value]) => {
    if (value.length > 0) {
      searchParams.set(key, value.toString())
    } else {
      searchParams.delete(key)
    }
  })

  const searchParamsStringified = searchParams.toString()

  return `${path}${searchParamsStringified ? `?${searchParamsStringified}` : ""}`
}

function createQueryFromFilterValue(query: BlogFilters, data: FilterValue): Partial<Record<BlogFilters, string[]>> {
  console.info(data)
  if (Array.isArray(data)) {
    return { [query]: data.map(({ value }) => value) }
  }

  if (data?.value) {
    return { [query]: [data.value] }
  }

  return { [query]: [] }
}

function createFilterValuesFromQuery(options: SimpleSelectOptions[], value?: string): SimpleSelectOptions[] {
  return options.filter(({ value: optionValue }) => optionValue === value)
}

export const BlogIndex: FunctionComponent<BlogIndexPropsType> = ({ page, pagePagination, section }) => {
  const { push } = useRouter()
  const pathname = usePathname()
  const searchParams = useSearchParams()
  const href = useHref()
  const { t } = useTranslation(["corporate_blog"])
  const categoryOptions =
    pagePagination?.filters?.category.map(({ key, value }) => ({ label: value, value: key })) || []
  const shouldDisplayRelatedCards =
    pagePagination?.currentPage === 1 && !searchParams.get("category") && !!section.relatedCards.length
  const shouldEmphasizeTheFirstArticle =
    pagePagination?.currentPage === 1 &&
    !searchParams.get("category") &&
    section.emphasizeTheFirstArticle.includes("yes")
  const articles = pagePagination?.items || []
  const splitPosition = shouldEmphasizeTheFirstArticle ? 4 : DEFAULT_RELATED_CARDS_POSITION
  const shouldSplit = articles.length > splitPosition && (shouldDisplayRelatedCards || shouldEmphasizeTheFirstArticle)
  let results = []

  if (!shouldSplit) {
    results = [
      <BlogIndexResults
        emphasizeTheFirstArticle={shouldEmphasizeTheFirstArticle}
        key="all the results"
        page={page}
        articles={articles}
      />,
      shouldDisplayRelatedCards && <BlogRelatedCards key="the cards" cards={section.relatedCards} />,
    ].filter(Boolean)
  } else {
    results = [
      <BlogIndexResults
        emphasizeTheFirstArticle={shouldEmphasizeTheFirstArticle}
        key="the first results"
        page={page}
        articles={articles.slice(0, splitPosition) || []}
      />,
      shouldDisplayRelatedCards && <BlogRelatedCards key="the cards" cards={section.relatedCards} />,
      <BlogIndexResults key="the following results " page={page} articles={articles.slice(splitPosition) || []} />,
    ].filter(Boolean)
  }

  return (
    <Layout hasTopPadding hasBottomPadding className={styles.main}>
      <Layout.Column>
        <div
          className={styles.headerWrapper}
          style={{ "--backgroundImage": `url(${getAssetImportUrl(teasyTriangle)})` } as React.CSSProperties}
        >
          <div className={styles.headerTextWrapper}>
            <Title tag="h1" title={section.title} className={styles.title} />
            {!!section.description && (
              <RichText
                value={section.description}
                wrapperProps={{
                  className: styles.subtitle,
                  resetSpacing: true,
                  tag: "div",
                  weight: TEXT_WEIGHTS.BOLD,
                }}
              />
            )}
          </div>
          {section.showCategoryFilter.includes("yes") && !!pagePagination && !!pagePagination.filters && (
            // A wrapping DIV is mandatory to avoid the filters to have the full height of the grid block.
            <div>
              <FilterBar className={styles.filters}>
                <Filter
                  id="filter-category"
                  isMulti={false}
                  value={createFilterValuesFromQuery(categoryOptions, searchParams.get("category") ?? undefined)}
                  options={categoryOptions}
                  onChange={(data: FilterValue) => {
                    push(updateUrlWithQuery(href, createQueryFromFilterValue("category", data)))
                  }}
                  placeholder={t("corporate_blog.filters.category.placeholder")}
                />
              </FilterBar>
            </div>
          )}
        </div>
        {!!pagePagination && !!pagePagination.items.length && (
          <>
            {results}
            <Pagination
              itemPerPage={pagePagination.itemsPerPage}
              linkComponent={Link}
              linkParam="href"
              linkPathname={pathname}
              linkSearchParams={searchParams.toString()}
              totalCount={pagePagination.totalItems}
            />
          </>
        )}
      </Layout.Column>
    </Layout>
  )
}
