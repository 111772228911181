import type { FunctionComponent } from "react"

// eslint-disable-next-line no-restricted-imports
import { Pagination as PaginationUiKit } from "@jobteaser/ui-kit/components/Pagination/Pagination"

type PaginationProps = {
  className?: string
  totalCount: number
  itemPerPage?: number
  onPaginate?: () => void
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  linkComponent?: FunctionComponent<any>
  linkParam?: string
  linkPathname?: string
  linkSearchParams?: string
  withScrollToTop?: boolean
}

export const Pagination: FunctionComponent<PaginationProps> = props => {
  const { className, ...restProps } = props

  return <PaginationUiKit className={className} {...restProps} />
}
