import type { FunctionComponent } from "react"

import IconFire from "@jobteaser/ui-kit/assets/icons/jds_fire.svg"
import { Tag } from "@jobteaser/ui-kit/components/Tag/Tag"

type Props = {
  category: string
  className?: string
}

export const ArticleCategory: FunctionComponent<Props> = ({ category, className }) => {
  return (
    <Tag className={className} color="purple" icon={`#${IconFire.id}`} size="small">
      {category}
    </Tag>
  )
}
